/* eslint-disable camelcase */

import { useContext, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Tabs } from "@narmi/design_system";
import { ContentCard, Dialog, Row, formatNumber } from "cerulean";
import { featureEnabled } from "byzantine/src/Feature";
import { InstitutionFeatures } from "byzantine/src/types";
import OrganizationUser from "byzantine/src/OrganizationUser";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";

type SubUserLimitsDialog = {
  userWireLimits: OrganizationUser[];
  userACHLimits: OrganizationUser[];
  isOpen: boolean;
  onClose: () => void;
  features: InstitutionFeatures;
};

const SubUserLimitsDialog = ({
  userWireLimits,
  userACHLimits,
  isOpen,
  onClose,
  features,
}: SubUserLimitsDialog) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { ach_payment_disclosure } = useContext(InstitutionSettingsContext);
  const wireEnabled = featureEnabled(features, { or: ["wires"] });
  const achEnabled = featureEnabled(features, { or: ["ach"] });
  const achPaymentEnabled = featureEnabled(features, { or: ["ach_payments"] });

  const renderOrgLimits = (key: string) => {
    const orgLimits = features.limits?.[key]
      ? Object.entries(features.limits?.[key])
      : [];

    return (
      <>
        {orgLimits.length > 0 && (
          <ContentCard kind="bordered">
            <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
              Organizational limit
            </h3>
            <div className="margin--bottom--l">
              This is the amount your organization is allowed to send during the
              specified time period. The sum of individual user limits cannot
              exceed this amount.
            </div>
            <div className="access-manager-limits">
              {orgLimits.map(([time, value]) => (
                <div className="subuser-limits" key={`${key}_${time}`}>
                  <Row>
                    <Row.Item>
                      <div>{time.toString()} day limit</div>
                    </Row.Item>
                    <Row.Item shrink>
                      {formatNumber(value, "currency")}
                    </Row.Item>
                  </Row>
                </div>
              ))}
            </div>
          </ContentCard>
        )}
      </>
    );
  };

  const renderUserLimits = (key: string) => {
    const userLimits = key === "ach" ? userACHLimits : userWireLimits;

    return (
      <>
        {userLimits.length > 0 && (
          <div className="margin--top--l">
            <ContentCard kind="bordered">
              <h3 className="fontSize--m fontFamily--body padding--bottom--xs">
                Individual user limits
              </h3>
              <div className="margin--bottom--l">
                These are the amounts each user is allowed to send as long as
                the org limit allows it. Individual limits cannot exceed the
                organizational limit during the specified time period.
              </div>
              <div className="access-manager-limits">
                {userLimits.map((orgUser, index) => (
                  <div className="subuser-limits" key={`subuser__${index}`}>
                    <Row>
                      <Row.Item>
                        <div>
                          {orgUser.user.first_name} {orgUser.user.last_name}
                        </div>
                      </Row.Item>
                      <Row.Item shrink>
                        {formatNumber(
                          orgUser.role.limits?.[key]?.[1],
                          "currency",
                        )}
                        /day
                      </Row.Item>
                    </Row>
                  </div>
                ))}
              </div>
            </ContentCard>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog isOpen={isOpen} title="Limits" onUserDismiss={onClose}>
      <div className="margin--top--s">
        <Tabs
          onTabChange={(index: number) => setSelectedTabIndex(index)}
          selectedIndex={selectedTabIndex}
        >
          <Tabs.List>
            {wireEnabled ? <Tabs.Tab label="Wire limits" tabId="wire" /> : null}
            {achEnabled ? <Tabs.Tab label="ACH limits" tabId="ach" /> : null}
            {achPaymentEnabled ? (
              <Tabs.Tab label="Transfer limits" tabId="ach-payment" />
            ) : null}
          </Tabs.List>
          <Tabs.Panel tabId="wire">
            <div className="margin--top--s">
              {renderOrgLimits("wire")}
              {renderUserLimits("wire")}
            </div>
          </Tabs.Panel>
          <Tabs.Panel tabId="ach">
            <div className="margin--top--s">
              {renderOrgLimits("ach_push")}
              {renderUserLimits("ach_push")}
            </div>
          </Tabs.Panel>
          {/* this is the same content as the ach dialog from the ach payment flow */}
          <Tabs.Panel tabId="ach-payment">
            <div className="margin--top--s">
              {ach_payment_disclosure ? (
                <div className="fontColor--secondary">
                  <ReactMarkdown linkTarget="_blank">
                    {ach_payment_disclosure}
                  </ReactMarkdown>
                </div>
              ) : null}
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </Dialog>
  );
};

export default SubUserLimitsDialog;
