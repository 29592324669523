import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  ContextForm,
  Error,
  Tooltip,
  formatNumber,
} from "cerulean";
import PropTypes from "prop-types";
import Feature, { featureEnabled, featureEquals } from "byzantine/src/Feature";
import { MODEL_STR } from "byzantine/src/Role";
import utils from "../../utils";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import AccountCheckList from "../AccountCheckList";
import SectionCard from "../SectionCard";
import AmountTextInput from "../form/AmountTextInput";
import SubUserLimitsDialog from "./SubUserLimitsDialog";

export default function OrganizationAccessLevelExtraPermissions({
  error,
  features,
  formData,
  organizationUsers,
  onChange,
  dualApprovalRequired,
  isUsingConsumerBillpay,
}) {
  if (formData.accessLevel !== "Collaborator") return null;

  const [isLimitsDialogOpen, setIsLimitsDialogOpen] = useState(false);

  const toggleLimitsDialog = () => {
    setIsLimitsDialogOpen(!isLimitsDialogOpen);
  };

  const fedwireTemplatesEnabled = featureEquals(
    features,
    "wire_templates",
    "fedwire",
  );

  const businessBillpayEnabled =
    featureEnabled(features, { or: ["bill_pay"] }) && !isUsingConsumerBillpay;

  const subUsersWithACHLimits = organizationUsers?.filter(
    (user) => user.role.limits?.ach_push?.[1] > 0,
  );
  const subUsersWithWireLimits = organizationUsers?.filter(
    (user) => user.role.limits?.wire?.[1] > 0,
  );
  const subUsersWithUnverifiedACHLimits = organizationUsers?.filter(
    (user) => user.role.limits?.unverified_ach_push?.[1] > 0,
  );

  // do we have the proper permissions for sub user limits
  const subUserLimitsPermission = features.business_subuser_limits;

  // if there are no external accounts and the user does not have permission to add an external account,
  // then we SHOULD NOT display the ACH limits field, but if they do have permission to add an external account,
  // then we SHOULD display the ACH limits field
  const addExternalAccountsWithNoAccounts = () => {
    if (!subUserLimitsPermission) return false;
    if (
      !formData.externalAccounts.length &&
      formData[MODEL_STR.EXTERNAL_ACCOUNT]
    ) {
      return true;
    }
    if (
      !formData.externalAccounts.length &&
      !formData[MODEL_STR.EXTERNAL_ACCOUNT]
    ) {
      return false;
    }
    return true;
  };

  const [displayACHLimits, setDisplayACHLimits] = useState(
    addExternalAccountsWithNoAccounts,
  );

  // as accounts are checked/unchecked, we need to update the values of any affected permissions
  useEffect(() => {
    if (!formData[MODEL_STR.ACCOUNT]) {
      const update = {};
      OrganizationAccessLevelExtraPermissions.affectedPermissionsRequiringAllAccounts.forEach(
        (model) => {
          update[model] = false;
        },
      );
      onChange(update);
    }
  }, [formData.accounts, formData[MODEL_STR.ACCOUNT]]);

  const [displayAddExternalAccount, setDisplayAddExternalAccount] = useState(
    utils.allAreSelected(formData.externalAccounts) &&
      formData[MODEL_STR.ACH_TRANSFER],
  );

  // as externalAccounts are checked/unchecked, we need to update the values of any affected permissions
  // if make external transfers is unchecked, then we need to uncheck the add external account permission
  useEffect(() => {
    if (
      !utils.allAreSelected(formData.externalAccounts) ||
      !formData[MODEL_STR.ACH_TRANSFER]
    ) {
      setDisplayAddExternalAccount(false);
      onChange({ [MODEL_STR.EXTERNAL_ACCOUNT]: false });
    } else {
      setDisplayAddExternalAccount(true);
    }
  }, [formData[MODEL_STR.ACH_TRANSFER], formData.externalAccounts]);

  // if the org has no external accounts and we don't have permission to add an external account,
  // then we want to update the display for the ach limits field
  useEffect(() => {
    if (!formData.externalAccounts.length && subUserLimitsPermission) {
      setDisplayACHLimits(formData[MODEL_STR.EXTERNAL_ACCOUNT]);
    }
  }, [formData[MODEL_STR.EXTERNAL_ACCOUNT]]);

  const validateACHLimits = (value, allFields) => {
    // sub-user ach limits must be less than or equal to the organization's daily ACH Push limits
    // sub-user ach limits can only be set if an external account is selected
    if (allFields.accessLevel !== "Collaborator") return null;
    const subUserLimits = utils.parseValueAsFloat(value);
    const hasSelectedExternalAccount = allFields.externalAccounts.some(
      (a) => a.selected,
    );
    const canMakeExternalTransfers = allFields[MODEL_STR.ACH_TRANSFER];
    if (subUserLimits === 0 && canMakeExternalTransfers) {
      return "Daily limit must be greater than $0";
    }
    // If there are no external accounts linked to the organization & the user does not have permission to
    // add an external account, then the user should not be able to set a limit.
    if (
      subUserLimits &&
      !formData.externalAccounts.length &&
      !allFields[MODEL_STR.EXTERNAL_ACCOUNT]
    ) {
      return "Must have ability to add account to set a limit";
    }
    if (
      subUserLimits &&
      !hasSelectedExternalAccount &&
      formData.externalAccounts.length
    )
      return "Must select at least one account to set a limit";
    // only need to check the daily ach_push limit
    const orgDailyLimits = features.limits?.ach_push?.[1];
    if (orgDailyLimits && subUserLimits > orgDailyLimits)
      return `Must be less than or equal to ${formatNumber(
        orgDailyLimits,
        "currency",
      )}`;
    return null;
  };

  const validateWireLimits = (value, allFields) => {
    // sub-user wire limits must be less than or equal to the organization's daily wire limits
    if (allFields.accessLevel !== "Collaborator") return null;
    const subUserLimits = utils.parseValueAsFloat(value);
    const canMakeWireTransfers = allFields[MODEL_STR.WIRE_TRANSACTION];
    if (subUserLimits === 0 && canMakeWireTransfers) {
      return "Daily limit must be greater than $0";
    }
    // only need to check the daily wire limit
    const orgDailyLimits = features.limits?.wire?.[1];
    if (orgDailyLimits && subUserLimits > orgDailyLimits) {
      return `Must be less than or equal to ${formatNumber(
        orgDailyLimits,
        "currency",
      )}`;
    }
    return null;
  };

  const validateACHPaymentLimits = (value, allFields) => {
    if (allFields.accessLevel !== "Collaborator") {
      return null;
    }

    const subUserLimits = utils.parseValueAsFloat(value);
    const canMakeACHPayment = allFields[MODEL_STR.ACH_PAYMENT];
    if (subUserLimits === 0 && canMakeACHPayment) {
      return "Daily limit must be greater than $0";
    }

    // sub user ACH payment limits must be less than or equal to the organization's daily ACH payment limits
    const orgDailyLimits = features.limits?.unverified_ach_push?.[1];
    if (orgDailyLimits && subUserLimits > orgDailyLimits) {
      return `Must be less than or equal to ${formatNumber(
        orgDailyLimits,
        "currency",
      )}`;
    }

    return null;
  };

  const doesOrgOrSubUserWireLimitsExist =
    features.limits?.wire || Boolean(subUsersWithWireLimits?.length);
  const doesOrgOrSubUserACHLimitsExist =
    features.limits?.ach_push || Boolean(subUsersWithACHLimits?.length);
  const doesOrgOrSubUserACHPaymentLimitsExist =
    features.limits?.unverified_ach_push ||
    Boolean(subUsersWithUnverifiedACHLimits?.length);

  const displayLimitsDialog =
    doesOrgOrSubUserACHLimitsExist ||
    doesOrgOrSubUserACHPaymentLimitsExist ||
    doesOrgOrSubUserWireLimitsExist;

  return (
    <div className="padding--top--l">
      <SectionCard isLoading={false} paddingSize={null}>
        <div className="padding--x--l padding--top--l">
          <SectionCard.Title
            text="Permissions"
            button={
              subUserLimitsPermission && (
                <Feature
                  features={features}
                  or={["wires", "ach", "ach_payments"]}
                >
                  {displayLimitsDialog && (
                    <Button
                      kind="plain"
                      label="View limits"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleLimitsDialog();
                      }}
                    />
                  )}
                </Feature>
              )
            }
          />
        </div>
        {!!formData[MODEL_STR.ACCOUNT] && (
          <h5 className="title-style fontWeight--bold">Money Movement</h5>
        )}
        <div className="permission-check-list">
          <Feature features={features} flag="internal_transfers">
            <div className="inverted-primary-color-checkbox">
              <ContextForm.Field style={{ marginBottom: "0px" }}>
                <Checkbox
                  field={MODEL_STR.INTRA_INSTITUTION_TRANSFER}
                  label="Make internal transfers"
                />
              </ContextForm.Field>
            </div>
          </Feature>
          <Feature features={features} flag="ach_payments">
            <div className="inverted-primary-color-checkbox">
              <div
                className="alignChild--left--center"
                style={{ gap: "var(--space-xxs)" }}
              >
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.ACH_PAYMENT}
                    label="Send ACH Payments"
                  />
                </ContextForm.Field>
              </div>
              {formData[MODEL_STR.ACH_PAYMENT] && subUserLimitsPermission && (
                <div className="user-limits">
                  <ContextForm.Field
                    style={{ marginBottom: "0px" }}
                    validate={validateACHPaymentLimits}
                  >
                    <AmountTextInput
                      field="ach_payment_limits"
                      label="Daily limits"
                    />
                  </ContextForm.Field>
                </div>
              )}
            </div>
          </Feature>
          <Feature features={features} flag="ach">
            <div className="inverted-primary-color-checkbox">
              <ContextForm.Field style={{ marginBottom: "0px" }}>
                <Checkbox
                  field={MODEL_STR.ACH_TRANSFER}
                  label="Make external transfers"
                />
              </ContextForm.Field>
              {formData[MODEL_STR.ACH_TRANSFER] && (
                <>
                  <ContextForm.Field
                    required
                    style={{ marginBottom: "0px", borderTop: "none" }}
                  >
                    <AccountCheckList
                      accessLevel={formData.accessLevel}
                      header="External Accounts"
                      field="externalAccounts"
                      containerStyle="external-account-checklist"
                    />
                  </ContextForm.Field>
                  {displayACHLimits && (
                    <div className="user-limits">
                      <ContextForm.Field
                        style={{ marginBottom: "0px" }}
                        validate={validateACHLimits}
                      >
                        <AmountTextInput
                          field="ach_limits"
                          label="Daily limit"
                        />
                      </ContextForm.Field>
                    </div>
                  )}
                </>
              )}
            </div>
          </Feature>
          {displayAddExternalAccount && (
            // access to all external accounts (or 0 existing so far) is a prereq for this permission
            <Feature features={features} flag="ach">
              <div className="inverted-primary-color-checkbox">
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.EXTERNAL_ACCOUNT}
                    label="Add external accounts"
                  />
                </ContextForm.Field>
              </div>
            </Feature>
          )}
          <Feature features={features} flag="wires">
            <div className="inverted-primary-color-checkbox">
              <div
                className="alignChild--left--center"
                style={{ gap: "var(--space-xxs)" }}
              >
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.WIRE_TRANSACTION}
                    label={
                      fedwireTemplatesEnabled
                        ? "Full access to wire capabilities"
                        : "Send wires"
                    }
                  />
                </ContextForm.Field>
                {fedwireTemplatesEnabled && (
                  <Tooltip text="This includes the ability to send wires with or without a template and manage templates and recipients.">
                    <span className="narmi-icon-info"></span>
                  </Tooltip>
                )}
              </div>
              {formData[MODEL_STR.WIRE_TRANSACTION] &&
                subUserLimitsPermission && (
                  <div className="user-limits">
                    <ContextForm.Field
                      style={{ marginBottom: "0px" }}
                      validate={validateWireLimits}
                    >
                      <AmountTextInput
                        field="wire_limits"
                        label="Daily limits"
                      />
                    </ContextForm.Field>
                  </div>
                )}
            </div>
          </Feature>
          <Feature features={features} flag="ach_payments">
            <div className="inverted-primary-color-checkbox">
              <div
                className="alignChild--left--center"
                style={{ gap: "var(--space-xxs)" }}
              >
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.RECIPIENT}
                    label="Manage payment recipients"
                  />
                </ContextForm.Field>
              </div>
            </div>
          </Feature>
          {businessBillpayEnabled && (
            <>
              <div className="inverted-primary-color-checkbox">
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.PAYMENT}
                    label="Pay bills via bill pay"
                  />
                </ContextForm.Field>
              </div>
              <div className="inverted-primary-color-checkbox">
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.PAYEE}
                    label="Add or delete recipients for bill pay"
                  />
                </ContextForm.Field>
              </div>
            </>
          )}
          <Feature features={features} flag="rdc">
            <div className="inverted-primary-color-checkbox">
              <ContextForm.Field style={{ marginBottom: "0px" }}>
                <Checkbox
                  field={MODEL_STR.RDC}
                  label="Make mobile check deposits"
                />
              </ContextForm.Field>
            </div>
          </Feature>
          {dualApprovalRequired && (
            <div className="inverted-primary-color-checkbox">
              <ContextForm.Field style={{ marginBottom: "0px" }}>
                <Checkbox
                  field={MODEL_STR.DUAL_APPROVAL_REQUEST}
                  label="Approve transactions with dual approvals"
                />
              </ContextForm.Field>
            </div>
          )}
          {formData[MODEL_STR.ACCOUNT] ? (
            <Feature features={features} flag="cards">
              <div className="inverted-primary-color-checkbox">
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.CARD}
                    label="Freeze or unfreeze debit/credit cards"
                  />
                </ContextForm.Field>
              </div>
            </Feature>
          ) : null}{" "}
        </div>
        {/* .permission-check-list */}
        {formData[MODEL_STR.ACCOUNT] ? (
          <Feature features={features} flag="statements">
            <>
              <h5 className="title-style account-activity-title-style fontWeight--bold">
                Account Activity
              </h5>
              <div className="permission-check-list">
                <div className="inverted-primary-color-checkbox">
                  <ContextForm.Field style={{ marginBottom: "0px" }}>
                    <Checkbox
                      field={MODEL_STR.DOCUMENTS}
                      label="View account statements"
                    />
                  </ContextForm.Field>
                </div>
              </div>
            </>
          </Feature>
        ) : null}
      </SectionCard>
      <InstitutionSettingsContextProvider>
        <SubUserLimitsDialog
          userWireLimits={subUsersWithWireLimits}
          userACHLimits={subUsersWithACHLimits}
          isOpen={isLimitsDialogOpen}
          onClose={toggleLimitsDialog}
          features={features}
        />
      </InstitutionSettingsContextProvider>
      <Error error={error} />
    </div>
  );
}

OrganizationAccessLevelExtraPermissions.propTypes = {
  error: PropTypes.string,
  features: PropTypes.object,
  organizationUsers: PropTypes.array,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  dualApprovalRequired: PropTypes.bool,
  isUsingConsumerBillpay: PropTypes.bool,
};

OrganizationAccessLevelExtraPermissions.affectedPermissions = [
  MODEL_STR.INTRA_INSTITUTION_TRANSFER,
  MODEL_STR.ACH_TRANSFER,
  MODEL_STR.EXTERNAL_ACCOUNT,
  MODEL_STR.WIRE_TRANSACTION,
  MODEL_STR.PAYMENT,
  MODEL_STR.PAYEE,
  MODEL_STR.DUAL_APPROVAL_REQUEST,
  MODEL_STR.RDC,
  MODEL_STR.CARD,
  MODEL_STR.DOCUMENTS,
  MODEL_STR.ACCOUNT,
  MODEL_STR.ACH_PAYMENT,
  MODEL_STR.RECIPIENT,
];

OrganizationAccessLevelExtraPermissions.affectedPermissionsRequiringAllAccounts =
  [MODEL_STR.CARD, MODEL_STR.DOCUMENTS];
